<template>
  <div class="page">
     <a-tabs default-active-key="1">
      <a-tab-pane key="1" tab="店铺子账号管理">
        <shop-manage></shop-manage>
      </a-tab-pane>
      <a-tab-pane key="2" tab="权限组管理" force-render>
        <role-manage></role-manage>
      </a-tab-pane>
    
    </a-tabs>
  </div>
</template>

<script>
  export default {
    name: 'PermissionsConfig',
    components: {
      shopManage: () => import('./shopManage'),
      roleManage: () => import('./roleManage')
    },
    data() {
      return {
      };
    },
    methods: {
    },
  }
</script>

<style scoped>
</style>